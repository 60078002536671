<template>
  <div class="orderlist bj order-container">
    <div>
      <div class="title">
        <el-button
          type="primary"
          icon="el-icon-refresh"
          class="bjsearch"
          @click="Refresh"
        ></el-button>
        <!-- <el-button type="success" @click="listHandle">生成供货单</el-button> -->

        <div class="inputs top">
          <span class="span">支付金额：</span>
          <el-input
            v-model="orderAmtStart"
            @blur="
              () =>
                (orderAmtStart = utilsTool.filterUnNumber(orderAmtStart, true))
            "
            @change="changeAmt"
            placeholder="支付金额起"
            clearable
          ></el-input>
        </div>
        <div class="mid">-</div>
        <div class="inputs top">
          <el-input
            v-model="orderAmtEnd"
            @blur="
              () => (orderAmtEnd = utilsTool.filterUnNumber(orderAmtEnd, true))
            "
            @change="changeAmt"
            placeholder="支付金额止"
            clearable
          ></el-input>
        </div>
        <div class="inputs top">
          <el-input
            v-model="from1.member_name"
            placeholder="客户名称"
            clearable
          ></el-input>
        </div>
        <div class="inputs top mr10">
          <el-input
            v-model="from1.mobile"
            placeholder="客户电话"
            clearable
          ></el-input>
        </div>
        <div class="inputs mr10">
          <span class="span">结算时间：</span>
          <el-date-picker
            v-model="from1.wx_settlement_time"
            :time-arrow-control="true"
            type="datetimerange"
            format="yyyy-MM-dd HH:mm"
            value-format="yyyy-MM-dd HH:mm"
            range-separator="—"
            start-placeholder="结算时间起"
            end-placeholder="结算时间止"
          >
          </el-date-picker>
        </div>
        <div class="inputs">
          <el-select
            v-model="from1.wx_settlement_status"
            clearable
            placeholder="请选择结算状态"
          >
            <el-option label="未结算" value="0"></el-option>
            <el-option label="已结算" value="1"></el-option>
          </el-select>
        </div>
      </div>
      <div class="seach order-wrapper">
        <div class="inputs">
          <el-select
            v-model="from1.logistic_business_id"
            clearable
            placeholder="请选择集配中心"
          >
            <el-option
              v-for="item of options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-input
            v-model="from1.order_no"
            placeholder="请输入订单编号"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-input
            v-model="from1.trade_no"
            placeholder="请输入微信交易单号"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <span class="span">支付时间：</span>
          <el-date-picker
            @change="payChange"
            v-model="pay_at"
            :time-arrow-control="true"
            type="datetimerange"
            format="yyyy-MM-dd HH:mm"
            value-format="yyyy-MM-dd HH:mm"
            range-separator="—"
            start-placeholder="支付时间起"
            end-placeholder="支付时间止"
          >
          </el-date-picker>
        </div>
        <div class="inputs multiple-select-long">
          <el-select
            @change="cityChange"
            filterable
            multiple
            v-model="from1.logistics_id"
            clearable
            collapse-tags
            placeholder="选择城市仓"
          >
            <li
              class="el-select-dropdown__item"
              :class="{ selected: serviceSelectedAll }"
              @click="onServiceSelectedAll"
            >
              <span>全选</span>
            </li>
            <el-option
              v-for="item in storelist"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="inputs multiple-select">
          <el-select
            v-model="from1.delivery_id"
            multiple
            clearable
            placeholder="选择自提点"
          >
            <el-option
              v-for="item in selflist"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="inputs">
          <span class="span">创建时间：</span>
          <el-date-picker
            @change="create_atChange"
            v-model="create_at"
            :time-arrow-control="true"
            type="datetimerange"
            format="yyyy-MM-dd  HH:mm"
            value-format="yyyy-MM-dd  HH:mm"
            range-separator="—"
            start-placeholder="创建时间起"
            end-placeholder="创建时间止"
          >
          </el-date-picker>
        </div>
        <div class="inputs">
          <el-select v-model="from1.state" clearable  multiple placeholder="请选择状态">
            <el-option label="待付款" value="1"></el-option>
            <el-option label="已付款" value="2"></el-option>
            <el-option label="已取消" value="3"></el-option>
            <el-option label="已完成" value="5"></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-select v-model="from1.supply" clearable placeholder="请选择供货状态">
            <el-option label="未订货" value="-1"></el-option>
            <el-option label="订货中" value="1"></el-option>
            <el-option label="已生成供货单" value="2"></el-option>
          </el-select>
        </div>
        <el-button @click="search" type="primary" icon="el-icon-search"
          >查询</el-button
        >
        <el-button
          :disabled="!tableData.length || disabledExport"
          @click="onHandleExport"
          type="warning"
          icon="el-icon-download"
          >导出</el-button
        >
      </div>
    </div>

    <div class="auto-table-flex">
      <el-table
        :data="tableData"
        height="100%"
        v-loading="loading"
        :border="true"
        :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
        ref="multipleTable"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column type="index" width="100px" align="center" label="序号">
        </el-table-column>
        <el-table-column
          prop="logistic_business"
          align="center"
          label="集配中心"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.logistic_business">{{
              $empty.empty(scope.row.logistic_business.name)
            }}</span>
            <span v-else>—</span>
          </template>
        </el-table-column>
        <el-table-column prop="order_no" align="center" label="订单编号">
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.order_no) }}
          </template>
        </el-table-column>
        <el-table-column prop="trade_no" align="center" label="微信交易单号"
          ><template slot-scope="scope">
            {{ scope.row.trade_no || "—" }}
          </template></el-table-column
        >
        <el-table-column prop="create_time" align="center" label="订单创建时间">
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.create_time) }}
          </template>
        </el-table-column>
        <el-table-column prop="pay_at" align="center" label="客户名称">
          <template slot-scope="scope" v-if="scope.row.member">
            {{ $empty.empty(scope.row.member.fullname) }}
          </template>
        </el-table-column>
        <el-table-column prop="pay_at" align="center" label="客户电话">
          <template slot-scope="scope" v-if="scope.row.member">
            {{ $empty.empty(scope.row.member.mobile) }}
          </template>
        </el-table-column>
        <el-table-column prop="pay_at" align="center" label="支付时间">
          <template slot-scope="scope">
            {{ $empty.time(scope.row.pay_at) }}
          </template>
        </el-table-column>
        <el-table-column prop="name" align="center" label="订单状态">
          <template slot-scope="scope">
            <span v-if="scope.row.state == 1">待付款</span>
            <span v-else-if="scope.row.state == 2">已付款</span>
            <span v-else-if="scope.row.state == 3">已取消</span>
            <span v-else-if="scope.row.state == 4 && scope.row.supply == 2"
              >订货中</span
            >
            <span v-else-if="scope.row.state == 4">已生成供货单</span>
            <span v-else-if="scope.row.state == 5">已完成</span>
            <span v-else>—</span>
          </template>
        </el-table-column>
        <el-table-column prop="name" align="center" label="订单金额">
          <template slot-scope="scope">
            {{ $empty.empty(tool.format_money(scope.row.order_amt)) }}
          </template>
        </el-table-column>

        <!-- 商品改版新增 start -->
        <el-table-column prop="ori_order_amt" align="center" label="支付总金额">
          <template slot-scope="scope">
            {{
              (scope.row.ori_order_amt &&
                (scope.row.ori_order_amt / 100)?.toFixed(2)) ||
              "-"
            }}
          </template>
        </el-table-column>
        <el-table-column
          prop="real_order_diff_amt"
          align="center"
          label="退差价总金额"
          min-width="100"
        >
          <template slot-scope="scope">
            {{
              (scope.row.real_order_diff_amt &&
                (scope.row.real_order_diff_amt / 100)?.toFixed(2)) ||
              "-"
            }}
          </template>
        </el-table-column>
        <!-- 商品改版新增 end -->

        <el-table-column prop="name" align="center" label="城市仓">
          <template
            slot-scope="scope"
            v-if="
              scope.row.store_delivery && scope.row.store_delivery.logistics
            "
          >
            {{ $empty.empty(scope.row.store_delivery.logistics.name) }}
          </template>
        </el-table-column>
        <el-table-column prop="name" align="center" label="自提点">
          <template slot-scope="scope" v-if="scope.row.store_delivery">
            {{ $empty.empty(scope.row.store_delivery.name) }}
          </template>
        </el-table-column>

        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <router-link
              :to="{ name: 'orderDetail', query: { id: scope.row.id } }"
              class="main-color pointer text-none"
              >查看详情
            </router-link>
            <el-button
              type="text"
              v-if="scope.row.is_payment_order"
              @click="onHandlePay(scope.row)"
              >确认已付款</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { Loading } from "element-ui";
// import { BASE } from "@/api";
// import { getOrderExport } from "@/api/export/index";
// import { downLoadFlowBlob } from "@/utils/tools/base";
// import { getOffsetDate } from "@/utils/tools/date";
import { postLogisticsOrderListExport } from "@/api/export/center";
import utilsTool from "@/utils/tools/tool";
import cloneDeep from "lodash/cloneDeep";
import { postOrderConfirmPayment } from "@/api/general/order";

export default {
  name: "orderlist",
  components: {},
  data() {
    return {
      //列表新建页面初始参数
      loading: true,
      utilsTool,
      pay_at: "",
      create_at: [],
      orderAmtStart: "",
      orderAmtEnd: "",
      from: {
        order_amt: "",
        member_name: "",
        mobile: "",
        order_no: "",
        trade_no: "",
        logistic_business_id: "",
        pay_start: "",
        pay_end: "",
        wx_settlement_time: "",
        wx_settlement_status: "",
        logistics_id: "",
        delivery_id: "",
        state: [],
        supply:'',
        create_start:'',
        create_end:'',
        page: 1,
        pageSize: 10,
      },
      from1: {
        order_amt: "",
        member_name: "",
        mobile: "",
        order_no: "",
        trade_no: "",
        logistic_business_id: "",
        pay_start: "",
        pay_end: "",
        wx_settlement_time: "",
        wx_settlement_status: "",
        logistics_id: "",
        delivery_id: "",
        state: [],
        supply:'',
        create_start:'',
        create_end:'',
        page: 1,
        pageSize: 10,
      },
      tableData: [],
      currentPage: 1,
      total: 0,
      done: 2, // 已付款状态
      //列表页面不同功能参数
      authentication: [
        {
          id: -1,
          name: "全部",
        },
        {
          id: 0,
          name: "未认证",
        },
        {
          id: 1,
          name: "审核中",
        },
        {
          id: 2,
          name: "审核成功",
        },
        {
          id: 3,
          name: "审核失败",
        },
      ],
      options: [
        {
          value: "logistics",
          label: "集配",
        },
        {
          value: "delivery",
          label: "自提",
        },
      ],
      options: [],
      options1: [],
      storelist: [],
      selflist: [],
      logistics_id: "",
      list: [],
      list1: [],
      disabledExport: false, // 导出禁用
    };
  },
  computed: {
    // 全选的勾选状态 true、false
    serviceSelectedAll() {
      return this.from1.logistics_id.length === this.storelist.length;
    },
  },
  created() {
    if (window.location.href.indexOf("?") > -1) {
      /** 历史丢参问题，修复 start */
      const url = this.tool.getUrl(window.location.href);
      // 无效的值
      const invalid = ["", null, undefined, "null", "undefined"];
      url.page = (invalid.includes(url.page) && 1) || url.page;
      url.pageSize = (invalid.includes(url.pageSize) && 10) || url.pageSize;
      /** 历史丢参问题，修复 end */
      this.from = url;
      this.from1 = url;
      this.currentPage = Number(this.from1.page);
      this.from1.pageSize = Number(this.from1.pageSize);
      this.from.logistics_id = this.from.logistics_id
        ? this.from.logistics_id
        : "";
      this.from.logistic_business_id = this.from.logistic_business_id
        ? Number(this.from.logistic_business_id)
        : "";
      this.from1.logistics_id = this.from1.logistics_id
        ? this.from1.logistics_id
        : "";
      this.from1.logistic_business_id = this.from1.logistic_business_id
        ? Number(this.from.logistic_business_id)
        : "";

      if (this.from1.create_start) {
        this.create_at = [this.from1.create_start, this.from1.create_end];}
        else{
          this.create_at=[]
        }
      if (this.from1.pay_start) {
        this.pay_at = [this.from1.pay_start, this.from1.pay_end];
      } else {
        let date = new Date();
        this.pay_at = [
          new Date(date.getTime() - 3600 * 1000 * 24 * 7),
          new Date(),
        ];
        this.from1.pay_start = this.tool.timestampToTimemin(
          new Date(date.getTime() - 3600 * 1000 * 24 * 7)
        );
        this.from1.pay_end = this.tool.timestampToTimemin(new Date());
        this.from.pay_start = this.tool.timestampToTimemin(
          new Date(date.getTime() - 3600 * 1000 * 24 * 7)
        );
        this.from.pay_end = this.tool.timestampToTimemin(new Date());
      }
      // 特殊处理支付金额 参数
      let money = url.order_amt.join(",");
      this.orderAmtStart = url.order_amt[0] ? url.order_amt[0] : "";
      this.orderAmtEnd = url.order_amt[1] ? url.order_amt[1] : "";
      this.from1.order_amt = money;
      this.from.order_amt = money;
      console.log(this.pay_at, this.from1, this.from);
    } else {
      let date = new Date();
      this.pay_at = [
        new Date(date.getTime() - 3600 * 1000 * 24 * 7),
        new Date(),
      ];
      this.from1.pay_start = this.tool.timestampToTimemin(
        new Date(date.getTime() - 3600 * 1000 * 24 * 7)
      );
      this.from1.pay_end = this.tool.timestampToTimemin(new Date());
      this.from.pay_start = this.tool.timestampToTimemin(
        new Date(date.getTime() - 3600 * 1000 * 24 * 7)
      );
      this.from.pay_end = this.tool.timestampToTimemin(new Date());
    }
    if (this.from1.logistics_id) {
      this.getListData(this.from1.logistics_id);
    }
    this.hqlist();
    this.citylist();
    this.getBusinessList();
    //   this.seletlist();
    // const date = getOffsetDate(-7);
    // console.log("🆒 getOffsetDate", date);
  },
  methods: {
    /**
     * 金额发生改变
     */
    changeAmt() {
      if (
        this.orderAmtStart &&
        this.orderAmtEnd &&
        Number(this.orderAmtStart) > Number(this.orderAmtEnd)
      ) {
        this.orderAmtStart = "";
        this.orderAmtEnd = "";
        this.$message.warning("起始金额不得大于截止金额");
        this.from1.order_amt = "";
      } else {
        this.from1.order_amt = `${this.orderAmtStart},${this.orderAmtEnd}`;
      }
    },
    /**
     * 确认已付款
     */
    async onHandlePay(row) {
      const params = {
        order_id: row.id,
      };
      try {
        await postOrderConfirmPayment(params);
        // 接口成功后，不重新请求，直接修改状态
        const index = this.tableData.findIndex((item) => item.id == row.id);
        this.tableData[index].is_payment_order = false;
        this.tableData[index].state = this.done;
      } catch (err) {
        console.log("ajax postOrderConfirmPayment err", err);
      } finally {
      }
    },
    getBusinessList() {
      this.$api.general.businessList().then((res) => {
        this.options = res.data;
      });
    },
    getListData(val) {
      this.$api.general
        .deliveryList({
          logistics_id: val, //必须填写
          page: 1,
          pageSize: 1000,
        })
        .then((res) => {
          console.log(res, "获取数据");
          this.selflist = res.data.data;
        });
    },
    /**
     * 导出数据（下载中心）
     */
    async onHandleExport() {
      this.changeAmt();
      this.from = this.tool.DeepCopy({}, this.from1);
      /** 最原始的导出逻辑 */
      // let delivery_id = "",
      //   logistics_id = "";
      // if (
      //   (Array.isArray(this.from.delivery_id) &&
      //     this.from.delivery_id.length == 0) ||
      //   !this.from.delivery_id
      // ) {
      //   delete this.from.delivery_id;
      // } else {
      //   this.from.delivery_id.forEach((item) => {
      //     delivery_id += "&delivery_id[]=" + item;
      //   });
      // }
      // if (
      //   (Array.isArray(this.from.logistics_id) &&
      //     this.from.logistics_id.length == 0) ||
      //   !this.from.logistics_id
      // ) {
      //   delete this.from.logistics_id;
      // } else {
      //   this.from.logistics_id.forEach((item) => {
      //     logistics_id += "&logistics_id[]=" + item;
      //   });
      // }
      // getOrderExport
      // window.open(
      //   BASE.PRO1 + `/order/list/export?order_no=`
      //   + this.from.order_no
      //   + '&pay_end=' + this.from.pay_end
      //   + '&pay_start=' + this.from.pay_start
      //   + '&state=' + this.from.state
      //   + delivery_id
      //   + logistics_id
      //   + '&token=' + sessionStorage.getItem("token"), '_blank'
      // )
      // this.$api.general.orderExport(this.from).then((res) => {
      // });
      /** 触发导出事件 */
      const {
        order_amt,
        member_name,
        mobile,
        order_no,
        trade_no,
        pay_start,
        pay_end,
        wx_settlement_time,
        wx_settlement_status,
        state,
        logistic_business_id,
        delivery_id,
        logistics_id,
        create_start,
        create_end,
        supply
      } = this.from;
      const params = {
        order_amt,
        member_name,
        mobile,
        order_no,
        trade_no,
        pay_start,
        pay_end,
        state,
        delivery_id,
        wx_settlement_time,
        wx_settlement_status,
        logistics_id,
        logistic_business_id,
        create_start,
        create_end,
        supply
      };
      this.getAjaxOrderExport(params);
    },
    /**
     * 导出excel数据
     */
    async getAjaxOrderExport(params) {
      /** 旧导出逻辑 */
      // this.$message.success({
      //   message: "正在导出，请耐心等待",
      //   duration: 999,
      // });
      // try {
      //   const res = await getOrderExport(params);
      //   downLoadFlowBlob(res, res?.filename);
      //   this.$message.success("导出成功");
      //   // console.log("🆒 getAjaxOrderExport", res);
      // } catch (err) {
      //   this.$message.error("导出失败了");
      //   console.log("ajax err", err);
      //   console.log("ajax getAjaxOrderExport err", err);
      // } finally {
      //   this.$message.closeAll();
      // }
      /** 新导出逻辑 */
      this.disabledExport = true;
      const { username } = JSON.parse(sessionStorage.getItem("userInfo"));
      try {
        const query = {
          ...params,
          token: sessionStorage.getItem("token"),
          user_name: username,
        };
        delete query.page;
        delete query.pageSize;
        await postLogisticsOrderListExport(query);
        this.goExportCenter();
      } catch (err) {
        console.log("ajax postLogisticsOrderListExport err", err);
        this.disabledExport = false;
      }
    },
    /**
     * 导出后引导弹框
     */
    goExportCenter() {
      // 新窗口打开下载中心
      const routerInfo = this.$router.resolve({
        path: "/export-center",
      });
      window.open(routerInfo.href, "_blank");
      this.disabledExport = false;
    },
    citylist() {
      this.$api.general
        .logisticsList({
          name: "",
          page: 1,
          pageSize: 1000,
        })
        .then((res) => {
          console.log(res, "获取数据");
          this.storelist = res.data.data;
        });
    },
    onServiceSelectedAll() {
      if (this.serviceSelectedAll) {
        // 取消全选
        this.from1.logistics_id = [];
      } else {
        this.from1.logistics_id = this.storelist.map((item) => item.id);
      }
    },
    cityChange(val) {
      this.getListData(val);
    },
    handleSelectionChange(val) {
      // this.multipleSelection = val;
      console.log(val);
    },
    toggleSelection() {
      this.$nextTick(() => {
        this.$refs.multipleTable.toggleAllSelection();
      });
    },
    listHandle() {
      this.$confirm("确定要生成供货单吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          let load = Loading.service({ fullscreen: false });
          console.log(load);
          this.tool.debounce(() => {
            this.from = this.tool.DeepCopy({}, this.from1);
            if (
              (Array.isArray(this.from.delivery_id) &&
                this.from.delivery_id.length == 0) ||
              !this.from.delivery_id
            ) {
              delete this.from.delivery_id;
            }
            if (
              (Array.isArray(this.from.logistics_id) &&
                this.from.logistics_id.length == 0) ||
              !this.from.logistics_id
            ) {
              delete this.from.logistics_id;
            }
            this.$api.general
              .addOrder(this.from)
              .then((res) => {
                this.tool.message("已生成", "success");
                load.close();
                this.Refresh();
              })
              .catch((error) => {
                load.close();
              });
          });
        })
        .catch(() => {});
    },
    search() {
      this.changeAmt();
      this.from.page = 1;
      this.from1.page = 1;
      this.currentPage = 1;
      this.from = this.tool.DeepCopy({}, this.from1);
      if (
        (Array.isArray(this.from.delivery_id) &&
          this.from.delivery_id.length == 0) ||
        !this.from.delivery_id
      ) {
        delete this.from.delivery_id;
      }
      if (
        (Array.isArray(this.from.logistics_id) &&
          this.from.logistics_id.length == 0) ||
        !this.from.logistics_id
      ) {
        delete this.from.logistics_id;
      }
      this.redirectPath();
      this.hqlist();
    },
    changestore(e) {
      // console.log(e);
      this.ruleForm.name = JSON.parse(e).name;
      this.ruleForm.store_id = JSON.parse(e).id;
    },
    addclick() {
      console.log("新增");

      this.ruleForm = {
        business_type_code: "logistics", //集配：logistics 自提点：delivery
        store_id: "",
        name: "",
        itemdata: "",
        username: "",
        password: "",
      };

      this.centerDialogVisible = true;
      this.title = "新增中心账号";
    },
    tianku() {
      console.log(this.ruleForm);
      this.centerDialogVisible = false;
      this.$api.login.addbusiness(this.ruleForm).then((res) => {
        this.$message({
          type: "success",
          message: "添加成功",
        });
        this.Refresh();
      });
    },
    /**
     * 搜索参数添加到路径
     */
    redirectPath() {
      let tmpData = { ...this.from };
      // 修复支付金额路径报错
      tmpData.order_amt = tmpData.order_amt.split(",");
      this.tool.getResult(tmpData, window.location.href);
    },
    Refresh() {
      let date = new Date();
      this.pay_at = [
        new Date(date.getTime() - 3600 * 1000 * 24 * 7),
        new Date(),
      ];
      this.create_at =[]
      this.orderAmtStart = "";
      this.orderAmtEnd = "";
      this.from = {
        order_amt: "",
        member_name: "",
        mobile: "",
        order_no: "",
        trade_no: "",
        wx_settlement_time: "",
        wx_settlement_status: "",
        logistic_business_id: "",
        pay_start: this.tool.timestampToTimemin(
          new Date(date.getTime() - 3600 * 1000 * 24 * 7)
        ),
        pay_end: this.tool.timestampToTimemin(new Date()),
        logistics_id: "",
        delivery_id: "",
        state: [],
        supply:'',
        create_start:'',
        create_end:'',
        page: 1,
        pageSize: 10,
      };
      this.from1 = {
        order_amt: "",
        member_name: "",
        mobile: "",
        order_no: "",
        trade_no: "",
        logistic_business_id: "",
        pay_start: this.tool.timestampToTimemin(
          new Date(date.getTime() - 3600 * 1000 * 24 * 7)
        ),
        pay_end: this.tool.timestampToTimemin(new Date()),
        wx_settlement_time: "",
        wx_settlement_status: "",
        logistics_id: "",
        delivery_id: "",
        state: [],
        supply:'',
        create_start:'',
        create_end:'',
        page: 1,
        pageSize: 10,
      };
      this.currentPage = 1;
      this.redirectPath();
      console.log(this.from);
      this.hqlist();
    },
    handleSizeChange(val) {
      //   console.log(`每页 ${val} 条`);
      this.from.pageSize = val;
      this.from1.pageSize = val;
      this.redirectPath();
      this.hqlist();
    },
    handleCurrentChange(val) {
      this.from.page = val;
      this.from1.page = val;
      this.currentPage = val;
      this.redirectPath();
      this.hqlist();
    },
    payChange(val) {
      console.log(val);
      if (val) {
        this.from1.pay_start = val[0];
        this.from1.pay_end = val[1];
      } else {
        this.from1.pay_start = "";
        this.from1.pay_end = "";
      }
    },
    create_atChange(val) {
      console.log(val);
      if (val) {
        this.from1.create_start = val[0];
        this.from1.create_end = val[1];
      } else {
        this.from1.create_start = "";
        this.from1.create_end = "";
      }
    },
    handleEdit(index, row) {
      this.$confirm(
        row.status === 1
          ? "确定要禁用该中心账号吗？"
          : "确定要启用该中心账户吗？",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        }
      )
        .then(() => {
          this.$api.login
            .businessstatus({
              id: row.id,
              status: row.status === 1 ? 0 : 1,
            })
            .then((res) => {
              console.log(res, "禁用或者启用");
              this.$message({
                type: "success",
                message: row.status === 1 ? "禁用成功" : "启用成功",
              });
              this.hqlist();
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: row.status === 1 ? "取消禁用" : "取消启用",
          });
        });
    },

    selectda(index, row) {
      this.title = "修改中心账号";
      this.ruleForm.business_type_code = row.business_type_code;
      //修改参数显示不全，问题
      // if (row.business_type_code == "logistics") {
      //   this.list.forEach((el) => {
      //     if (el.id == row.store_id) {
      //       this.ruleForm.itemdata = JSON.stringify(el);
      //     }
      //   });
      // } else {
      //   this.list1.forEach((el) => {
      //     if (el.id == row.store_id) {
      //       this.ruleForm.itemdata = JSON.stringify(el);
      //     }
      //   });
      // }
      console.log(this.ruleForm, this.list, this.list);
      this.centerDialogVisible = true;
      // this.shownode = false;
      // this.$api.shipper.shipperinfo({ id: row.id }).then((res) => {
      //   console.log(res, "获取数据");
      //   this.info = res.data;
      //
      // });
    },
    hqlist() {
      this.loading = true;
      let form = cloneDeep(this.from);
      form.wx_settlement_time = Array.isArray(form.wx_settlement_time)
        ? form.wx_settlement_time.join(",")
        : form.wx_settlement_time;
      console.log(form);
      this.$api.general.orderList(form).then((res) => {
        console.log(res, "获取数据");
        this.tableData = res.data.data;
        this.total = res.data.total;
        this.loading = false;
      });
    },
    seletlist() {
      this.$api.login.unusedall().then((res) => {
        console.log(res, "获取所有可配置的自提点");
        this.list1 = res.data;
      });
      this.$api.login.logisticsunusedall().then((res) => {
        console.log(res, "获取所有可配置的城市仓");

        this.list = res.data;
      });
    },
  },
};
</script>
<style lang="scss">
.order-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  //   background: #ffffff;
  padding: 10px;

  .checked-box {
    padding: 13px 15px;
  }

  .order-wrapper {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    background: #ffffff;
    padding: 10px 15px;

    .inputs {
      margin-right: 10px;
      margin-bottom: 10px;
    }

    .multiple-select {
      width: 250px;
    }
    .multiple-select-long {
      width: 320px;
      .el-select__input {
        height: auto;
      }
    }
  }

  .top {
    display: flex;
    align-items: center;
    .span {
      width: 100px;
    }
    &:nth-of-type(1),
    &:nth-of-type(4),
    &:nth-of-type(5) {
      margin-left: 15px;
    }
  }
  .mid {
    height: 36px;
    line-height: 36px;
  }
  .title {
    background: #ffffff;
    padding-top: 10px;
    padding-left: 15px;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    .inputs {
      display: flex;
      align-items: center;
    }
    .seach {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;

      .multiple-select {
        width: 250px;
      }
    }

    .bjsearch {
      background: #333333;
      border-color: #333333;
    }
  }

  .auto-table-flex {
    flex-grow: 1;
    overflow-y: hidden;
  }

  .fy {
    // background-color: #000;
    box-sizing: border-box;
    padding: 10px 20px;
  }

  .contents {
    padding-left: 55px;
    padding-right: 55px;
    padding-top: 23px;

    box-sizing: border-box;

    .titlname {
      font-size: 17px;
      color: #21c2ac;
      margin-bottom: 25px;
    }

    .imgs {
      display: flex;

      .imglist {
        width: 165px;
        height: 123px;
        border: 1px dashed #a4adc5;
        padding: 14px 8px;
        text-align: center;
        border-radius: 7px;
        box-sizing: border-box;
        margin-right: 16px;

        .div {
          margin-top: 20px;
          padding-bottom: 20px;
        }

        // img{
        //     width: 100%;
        //     height: 100%;
        // }
      }
    }

    .disply {
      display: flex;
      margin-bottom: 22px;
    }

    .mr {
      margin-right: 31px;
    }

    .iteminput {
      display: flex;
      align-items: center;

      .wen {
        width: 77px;
        margin-right: 24px;
        color: #000;
        font-size: 14px;
        text-align: right;
      }

      .el-input {
        width: 302px;
      }
    }
  }

  .colors {
    color: #fa884c;
  }

  .times {
    margin-right: 8px;
  }

  // .el-picker-panel {
  //   left: 1245px !important;
  // }
}
</style>
