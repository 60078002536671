var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "orderlist bj order-container" }, [
    _c("div", [
      _c(
        "div",
        { staticClass: "title" },
        [
          _c("el-button", {
            staticClass: "bjsearch",
            attrs: { type: "primary", icon: "el-icon-refresh" },
            on: { click: _vm.Refresh },
          }),
          _c(
            "div",
            { staticClass: "inputs top" },
            [
              _c("span", { staticClass: "span" }, [_vm._v("支付金额：")]),
              _c("el-input", {
                attrs: { placeholder: "支付金额起", clearable: "" },
                on: {
                  blur: () =>
                    (_vm.orderAmtStart = _vm.utilsTool.filterUnNumber(
                      _vm.orderAmtStart,
                      true
                    )),
                  change: _vm.changeAmt,
                },
                model: {
                  value: _vm.orderAmtStart,
                  callback: function ($$v) {
                    _vm.orderAmtStart = $$v
                  },
                  expression: "orderAmtStart",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "mid" }, [_vm._v("-")]),
          _c(
            "div",
            { staticClass: "inputs top" },
            [
              _c("el-input", {
                attrs: { placeholder: "支付金额止", clearable: "" },
                on: {
                  blur: () =>
                    (_vm.orderAmtEnd = _vm.utilsTool.filterUnNumber(
                      _vm.orderAmtEnd,
                      true
                    )),
                  change: _vm.changeAmt,
                },
                model: {
                  value: _vm.orderAmtEnd,
                  callback: function ($$v) {
                    _vm.orderAmtEnd = $$v
                  },
                  expression: "orderAmtEnd",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs top" },
            [
              _c("el-input", {
                attrs: { placeholder: "客户名称", clearable: "" },
                model: {
                  value: _vm.from1.member_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.from1, "member_name", $$v)
                  },
                  expression: "from1.member_name",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs top mr10" },
            [
              _c("el-input", {
                attrs: { placeholder: "客户电话", clearable: "" },
                model: {
                  value: _vm.from1.mobile,
                  callback: function ($$v) {
                    _vm.$set(_vm.from1, "mobile", $$v)
                  },
                  expression: "from1.mobile",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs mr10" },
            [
              _c("span", { staticClass: "span" }, [_vm._v("结算时间：")]),
              _c("el-date-picker", {
                attrs: {
                  "time-arrow-control": true,
                  type: "datetimerange",
                  format: "yyyy-MM-dd HH:mm",
                  "value-format": "yyyy-MM-dd HH:mm",
                  "range-separator": "—",
                  "start-placeholder": "结算时间起",
                  "end-placeholder": "结算时间止",
                },
                model: {
                  value: _vm.from1.wx_settlement_time,
                  callback: function ($$v) {
                    _vm.$set(_vm.from1, "wx_settlement_time", $$v)
                  },
                  expression: "from1.wx_settlement_time",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择结算状态" },
                  model: {
                    value: _vm.from1.wx_settlement_status,
                    callback: function ($$v) {
                      _vm.$set(_vm.from1, "wx_settlement_status", $$v)
                    },
                    expression: "from1.wx_settlement_status",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "未结算", value: "0" } }),
                  _c("el-option", { attrs: { label: "已结算", value: "1" } }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "seach order-wrapper" },
        [
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择集配中心" },
                  model: {
                    value: _vm.from1.logistic_business_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.from1, "logistic_business_id", $$v)
                    },
                    expression: "from1.logistic_business_id",
                  },
                },
                _vm._l(_vm.options, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入订单编号", clearable: "" },
                model: {
                  value: _vm.from1.order_no,
                  callback: function ($$v) {
                    _vm.$set(_vm.from1, "order_no", $$v)
                  },
                  expression: "from1.order_no",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入微信交易单号", clearable: "" },
                model: {
                  value: _vm.from1.trade_no,
                  callback: function ($$v) {
                    _vm.$set(_vm.from1, "trade_no", $$v)
                  },
                  expression: "from1.trade_no",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c("span", { staticClass: "span" }, [_vm._v("支付时间：")]),
              _c("el-date-picker", {
                attrs: {
                  "time-arrow-control": true,
                  type: "datetimerange",
                  format: "yyyy-MM-dd HH:mm",
                  "value-format": "yyyy-MM-dd HH:mm",
                  "range-separator": "—",
                  "start-placeholder": "支付时间起",
                  "end-placeholder": "支付时间止",
                },
                on: { change: _vm.payChange },
                model: {
                  value: _vm.pay_at,
                  callback: function ($$v) {
                    _vm.pay_at = $$v
                  },
                  expression: "pay_at",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs multiple-select-long" },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    filterable: "",
                    multiple: "",
                    clearable: "",
                    "collapse-tags": "",
                    placeholder: "选择城市仓",
                  },
                  on: { change: _vm.cityChange },
                  model: {
                    value: _vm.from1.logistics_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.from1, "logistics_id", $$v)
                    },
                    expression: "from1.logistics_id",
                  },
                },
                [
                  _c(
                    "li",
                    {
                      staticClass: "el-select-dropdown__item",
                      class: { selected: _vm.serviceSelectedAll },
                      on: { click: _vm.onServiceSelectedAll },
                    },
                    [_c("span", [_vm._v("全选")])]
                  ),
                  _vm._l(_vm.storelist, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.id },
                    })
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs multiple-select" },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    multiple: "",
                    clearable: "",
                    placeholder: "选择自提点",
                  },
                  model: {
                    value: _vm.from1.delivery_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.from1, "delivery_id", $$v)
                    },
                    expression: "from1.delivery_id",
                  },
                },
                _vm._l(_vm.selflist, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c("span", { staticClass: "span" }, [_vm._v("创建时间：")]),
              _c("el-date-picker", {
                attrs: {
                  "time-arrow-control": true,
                  type: "datetimerange",
                  format: "yyyy-MM-dd  HH:mm",
                  "value-format": "yyyy-MM-dd  HH:mm",
                  "range-separator": "—",
                  "start-placeholder": "创建时间起",
                  "end-placeholder": "创建时间止",
                },
                on: { change: _vm.create_atChange },
                model: {
                  value: _vm.create_at,
                  callback: function ($$v) {
                    _vm.create_at = $$v
                  },
                  expression: "create_at",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    multiple: "",
                    placeholder: "请选择状态",
                  },
                  model: {
                    value: _vm.from1.state,
                    callback: function ($$v) {
                      _vm.$set(_vm.from1, "state", $$v)
                    },
                    expression: "from1.state",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "待付款", value: "1" } }),
                  _c("el-option", { attrs: { label: "已付款", value: "2" } }),
                  _c("el-option", { attrs: { label: "已取消", value: "3" } }),
                  _c("el-option", { attrs: { label: "已完成", value: "5" } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择供货状态" },
                  model: {
                    value: _vm.from1.supply,
                    callback: function ($$v) {
                      _vm.$set(_vm.from1, "supply", $$v)
                    },
                    expression: "from1.supply",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "未订货", value: "-1" } }),
                  _c("el-option", { attrs: { label: "订货中", value: "1" } }),
                  _c("el-option", {
                    attrs: { label: "已生成供货单", value: "2" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.search },
            },
            [_vm._v("查询")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                disabled: !_vm.tableData.length || _vm.disabledExport,
                type: "warning",
                icon: "el-icon-download",
              },
              on: { click: _vm.onHandleExport },
            },
            [_vm._v("导出")]
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "auto-table-flex" },
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            ref: "multipleTable",
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.tableData,
              height: "100%",
              border: true,
              "header-cell-style": { color: "#333333", background: "#EFF6FF" },
              "tooltip-effect": "dark",
            },
          },
          [
            _c("el-table-column", {
              attrs: {
                type: "index",
                width: "100px",
                align: "center",
                label: "序号",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "logistic_business",
                align: "center",
                label: "集配中心",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.logistic_business
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$empty.empty(
                                  scope.row.logistic_business.name
                                )
                              )
                            ),
                          ])
                        : _c("span", [_vm._v("—")]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { prop: "order_no", align: "center", label: "订单编号" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        " " + _vm._s(_vm.$empty.empty(scope.row.order_no)) + " "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "trade_no",
                align: "center",
                label: "微信交易单号",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(" " + _vm._s(scope.row.trade_no || "—") + " "),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "create_time",
                align: "center",
                label: "订单创建时间",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$empty.empty(scope.row.create_time)) +
                          " "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { prop: "pay_at", align: "center", label: "客户名称" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (scope) {
                      return scope.row.member
                        ? [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$empty.empty(scope.row.member.fullname)
                                ) +
                                " "
                            ),
                          ]
                        : undefined
                    },
                  },
                ],
                null,
                true
              ),
            }),
            _c("el-table-column", {
              attrs: { prop: "pay_at", align: "center", label: "客户电话" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (scope) {
                      return scope.row.member
                        ? [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$empty.empty(scope.row.member.mobile)
                                ) +
                                " "
                            ),
                          ]
                        : undefined
                    },
                  },
                ],
                null,
                true
              ),
            }),
            _c("el-table-column", {
              attrs: { prop: "pay_at", align: "center", label: "支付时间" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        " " + _vm._s(_vm.$empty.time(scope.row.pay_at)) + " "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { prop: "name", align: "center", label: "订单状态" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.state == 1
                        ? _c("span", [_vm._v("待付款")])
                        : scope.row.state == 2
                        ? _c("span", [_vm._v("已付款")])
                        : scope.row.state == 3
                        ? _c("span", [_vm._v("已取消")])
                        : scope.row.state == 4 && scope.row.supply == 2
                        ? _c("span", [_vm._v("订货中")])
                        : scope.row.state == 4
                        ? _c("span", [_vm._v("已生成供货单")])
                        : scope.row.state == 5
                        ? _c("span", [_vm._v("已完成")])
                        : _c("span", [_vm._v("—")]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { prop: "name", align: "center", label: "订单金额" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$empty.empty(
                              _vm.tool.format_money(scope.row.order_amt)
                            )
                          ) +
                          " "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "ori_order_amt",
                align: "center",
                label: "支付总金额",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            (scope.row.ori_order_amt &&
                              (scope.row.ori_order_amt / 100)?.toFixed(2)) ||
                              "-"
                          ) +
                          " "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "real_order_diff_amt",
                align: "center",
                label: "退差价总金额",
                "min-width": "100",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            (scope.row.real_order_diff_amt &&
                              (scope.row.real_order_diff_amt / 100)?.toFixed(
                                2
                              )) ||
                              "-"
                          ) +
                          " "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { prop: "name", align: "center", label: "城市仓" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (scope) {
                      return scope.row.store_delivery &&
                        scope.row.store_delivery.logistics
                        ? [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$empty.empty(
                                    scope.row.store_delivery.logistics.name
                                  )
                                ) +
                                " "
                            ),
                          ]
                        : undefined
                    },
                  },
                ],
                null,
                true
              ),
            }),
            _c("el-table-column", {
              attrs: { prop: "name", align: "center", label: "自提点" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (scope) {
                      return scope.row.store_delivery
                        ? [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$empty.empty(
                                    scope.row.store_delivery.name
                                  )
                                ) +
                                " "
                            ),
                          ]
                        : undefined
                    },
                  },
                ],
                null,
                true
              ),
            }),
            _c("el-table-column", {
              attrs: { label: "操作", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "router-link",
                        {
                          staticClass: "main-color pointer text-none",
                          attrs: {
                            to: {
                              name: "orderDetail",
                              query: { id: scope.row.id },
                            },
                          },
                        },
                        [_vm._v("查看详情 ")]
                      ),
                      scope.row.is_payment_order
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.onHandlePay(scope.row)
                                },
                              },
                            },
                            [_vm._v("确认已付款")]
                          )
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "fy" },
      [
        _c("el-pagination", {
          attrs: {
            type: "primary",
            background: "",
            "current-page": _vm.currentPage,
            "page-sizes": [10, 20, 50, 100],
            "page-size": 10,
            layout: "total, sizes, prev, pager, next, jumper",
            total: _vm.total,
          },
          on: {
            "size-change": _vm.handleSizeChange,
            "current-change": _vm.handleCurrentChange,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }