import axios from '@/api/http';
const BASE_URL = process.env.VUE_APP_GENERAL_URL;

/**
 * 总控-订单管理-订单列表-确认已付款
 * @param {*} data
 * @returns
 */
export function postOrderConfirmPayment(data = {}) {
  return axios(
    {
      method: 'POST',
      url: `${BASE_URL}/corporate/order/payment`,
      data
    }
  );
};
